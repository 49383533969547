<!--  -->
<template>
  <div class="">
    <div class="ywlybanner">
      <img src="../assets/ywly.jpg" alt="" />
    </div>
    <div class="top">
      <div class="xian"></div>
      <div class="tit">
        <div>自主经营业务</div>
        <div>Independent business</div>
      </div>
      <div class="xian"></div>
    </div>

    <div class="ywlycontent">
      <div
        class="ywlycontentTop"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <div v-if="index % 2 == 0" class="ywlycontentTop">
          <div class="ywlycontentleft">
            <img
              :src="
                'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId=' +
                item.file_id
              "
              alt=""
            />
          </div>
          <div class="ywlycontentright">
            <div class="lanse">
              <div class="st"></div>
              <div>
                <div class="ywlycontentTopTit">{{ item.title }}</div>
                <div class="ywlycontentTopbottom">Website construction</div>
              </div>
            </div>

            <div class="xiaoTit">
              {{ item.introduction }}
            </div>
          </div>
        </div>

        <div v-else class="ywlycontentTop">
          <div class="ywlycontentright">
            <div class="lanse">
              <div class="st"></div>
              <div>
                <div class="ywlycontentTopTit">{{ item.title }}</div>
                <div class="ywlycontentTopbottom">Website construction</div>
              </div>
            </div>

            <div class="xiaoTit">
              {{ item.introduction }}
            </div>
          </div>
          <div class="ywlycontentleft">
            <img
              :src="
                'http://tj-dasheng.com/api/sysFileInfo/public/preview?fileId=' +
                item.file_id
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dataList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$api
      .businessAreaapiList({})
      .then((res) => {
        // this.hzdwcontent = res.data
        this.dataList = res.data;
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.top {
  width: 437px;
  padding-top: 66px;
  margin: auto;
  margin-bottom: 52px;
  .xian {
    width: 96px;
    height: 2px;
    background: #e5e5e5;
    margin-top: 17px;
  }

  display: flex;
  justify-content: space-between;
  .tit {
    padding: 0 30px;
    text-align: center;
  }
  .tit div:nth-child(1) {
    font-size: 26px;
    color: #333333;
    letter-spacing: 2.6px;
    text-align: center;
    line-height: 36px;
  }
  .tit div:nth-child(2) {
    font-family: ArialMT;
    font-size: 14px;
    color: #cccccc;
    letter-spacing: 0.8px;
    text-align: center;
    line-height: 20px;
  }
}

.ywlybanner {
  // height: 280px;
  img {
    width: 100%;
    // height: 100%;
  }
}
.ywlycontent {
  width: 1200px;
  margin: auto;

  .ywlycontentleft {
    width: 462px;
    height: 288px;
    // background: #333333;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .ywlycontentright {
    padding: 42px 40px;
    .ywlycontentTopTit {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      color: #333333;
      letter-spacing: 1.26px;
    }
    .ywlycontentTopbottom {
      font-family: ArialMT;
      font-size: 14px;
      color: #cccccc;
      letter-spacing: 0.8px;
      line-height: 20px;
    }
    .lanse {
      padding-bottom: 14px;
      border-bottom: 1px solid #ccc;
      width: 658px;
      display: flex;
    }
  }
}
.ywlycontentTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 66px;
}
.st {
  background: #237bff;
  height: 46px;
  width: 4px;
  margin-right: 20px;
}
.xiaoTit {
  font-size: 15px;
  color: #5c5c5c;
  letter-spacing: 0.86px;
  line-height: 30px;
  width: 658px;
  margin-top: 23px;
}
</style>